import React from 'react';

import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { MappedPromoCards } from '@src/cms-components';
import { useCallback, useEffect, useState } from 'react';
import {
  LivePreviewSelect,
  LivePreviewToggle,
} from '@shared/contentstack/live-preview/widgets/live-preview-widgets';
import { LivePreviewHeader } from '@shared/contentstack/live-preview/widgets/live-preview-widgets.styles';
import { PROMO_CARDS_CARDS_MOCK } from '@shared/contentstack/live-preview/mock-data/promo-cards-mock';

const type_options = [
  { label: 'Default', value: 'boxed' },
  { label: 'Square', value: 'square' },
  { label: 'Tall', value: 'tall' },
];

const color_options = [
  { label: 'Light', value: 'light' },
  { label: 'Mid', value: 'mid' },
];

const PromoCardsPreviewPage = (props: any) => {
  const { pageContext } = props;
  const [type, setType] = useState('default');
  const [bgColor, setBgColor] = useState('light');
  const [secBgColor, setSecBgColor] = useState(true);
  const [livePreviewData, setLivePreviewData] = useState<any>({});

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'promo_cards',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;
    setLivePreviewData(response[0][0]);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return (
    <>
      <LivePreviewHeader>
        <LivePreviewSelect
          title="Promo Card Types"
          options={type_options}
          setSelectedItem={setType}
        />
        <LivePreviewSelect
          title="Background Color"
          options={color_options}
          setSelectedItem={setBgColor}
        />
        <LivePreviewToggle
          label="Light Secondary Color"
          toggleItem={setSecBgColor}
        />
      </LivePreviewHeader>

      {!!livePreviewData && (
        <MappedPromoCards
          {...{
            ...livePreviewData,
            cards: PROMO_CARDS_CARDS_MOCK,
            card_type: type,
            primary_background_color: bgColor,
            light_secondary_background: secBgColor,
          }}
        />
      )}
    </>
  );
};

export default PromoCardsPreviewPage;

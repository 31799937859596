export const PROMO_CARDS_CARDS_MOCK = [
  {
    icon_or_logo: {
      image: {
        _version: 3,
        is_dir: false,
        uid: 'blt95ab8cfaca281d18',
        ACL: {},
        content_type: 'image/png',
        created_at: '2022-12-11T20:13:37.023Z',
        created_by: 'blt0bfd0bd5be1f5557',
        file_size: '2087',
        filename: 'Find.png',
        parent_uid: null,
        tags: [],
        title: 'Find.png',
        updated_at: '2023-01-10T14:49:47.799Z',
        updated_by: 'blt0bfd0bd5be1f5557',
        publish_details: {
          environment: 'blt739e9675080fabac',
          locale: 'en-us',
          time: '2023-01-27T20:28:57.862Z',
          user: 'bltd2c4662f0a1e9bbd',
        },
        url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt95ab8cfaca281d18/639639f1c08ef0300c43e67d/Find.png',
      },
      image_alt_text: '',
    },
    _metadata: { uid: 'csb4b6a1a95f2b3d3e' },
    background_image: { image: null, image_alt_text: '' },
    background_color: { colors: null },
    card_headline: 'Find a Partner',
    card_content:
      'Looking to leverage the expertise of a leader in practice management?',
    cta_or_attribution: 'See our partners',
    url: 'https://assemblysoftware.com/integration-partners',
  },
  {
    icon_or_logo: {
      image: {
        _version: 2,
        is_dir: false,
        uid: 'bltcee190b38c2d996d',
        ACL: {},
        content_type: 'image/png',
        created_at: '2022-12-11T20:14:33.829Z',
        created_by: 'blt0bfd0bd5be1f5557',
        file_size: '1476',
        filename: 'Add.png',
        parent_uid: null,
        tags: [],
        title: 'Add.png',
        updated_at: '2023-01-10T14:50:47.350Z',
        updated_by: 'blt0bfd0bd5be1f5557',
        publish_details: {
          environment: 'blt739e9675080fabac',
          locale: 'en-us',
          time: '2023-01-27T20:28:57.880Z',
          user: 'bltd2c4662f0a1e9bbd',
        },
        url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltcee190b38c2d996d/63963a29dd8079109149f159/Add.png',
      },
      image_alt_text: '',
    },
    _metadata: { uid: 'cs95d92e7739fc5379' },
    background_image: { image: null, image_alt_text: '' },
    background_color: { colors: null },
    card_headline: 'Become a Partner',
    card_content:
      'Searching for a cloud-based solution that will help you become more efficient?',
    cta_or_attribution: 'Apply',
    url: '/referral-partner-program',
  },
];
